<template>
  <section>
    <h5>
      <v-icon class="mr-1" :color="getMapColor.shipmentItemMapColor">mdi-map-marker-radius</v-icon>
      {{title}}
    </h5>
    <p class="mt-2 mb-0">{{shipmentItem.locationName || '-'}}</p>
    <p class="text-caption grey--text">{{shipmentItem.locationAddress || '-'}}</p>
    <v-row>
      <v-col cols="12">
        <p class="ma-0 pa-0 text-subtitle-2 grey--text">{{picNoteTitle}}</p>
        <p class="text-body-2" v-html="replaceNoteToHtml(note)"></p>
      </v-col>
    </v-row>
    <section v-if="shipmentsDetails.length" cols="12" sm="4">
      <v-divider class="my-8"></v-divider>
      <div
        v-for="(shipmentDetail, shipmentDetailIndex) in shipmentsDetails"
        :key="shipmentDetailIndex"
      >
        <h5>
          <v-icon :color="getMapColor.shipmentDetailMapColor">
            mdi-map-marker-radius
          </v-icon>{{shipmentDetailTitle}} - {{shipmentDetailIndex + 1}}
        </h5>
        <p class="mt-2 mb-0">{{shipmentDetail.locationName || '-'}}</p>
        <p class="text-caption grey--text">{{shipmentDetail.cityName || '-'}}</p>
        <v-row>
          <v-col cols="12" sm="6">
            <p class="ma-0 pa-0 text-subtitle-2 grey--text">{{picNameTitle}}</p>
            <p class="text-body-2">{{shipmentDetail.picName || '-'}}</p>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="ma-0 pa-0 text-subtitle-2 grey--text">{{picPhoneTitle}}</p>
            <p class="text-body-2">{{shipmentDetail.picPhoneNo || '-'}}</p>
          </v-col>
          <v-col cols="12">
            <p class="ma-0 pa-0 text-subtitle-2 grey--text">{{picNoteTitle}}</p>
            <p class="text-body-2" v-html="replaceNoteToHtml(shipmentDetail.picNote)"></p>
          </v-col>
        </v-row>
      </div>
    </section>
  </section>
</template>

<script>
import { replaceNoteToHtml } from '@/helper/commonHelpers';

export default {
  props: {
    shipmentItem: {
      type: Object,
      default: () => {},
    },
    shipmentsDetails: {
      type: Array,
      default: () => [],
    },
    note: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: () => '',
    },
    picNameTitle: {
      type: String,
      default: () => '',
    },
    picPhoneTitle: {
      type: String,
      default: () => '',
    },
    picNoteTitle: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    shipmentDetailTitle() {
      if (this.type === 'ORIGIN') return 'Lokasi Multipick';
      return 'Lokasi Multidrop';
    },
    getMapColor() {
      if (this.type === 'ORIGIN' || this.type === 'BASP') {
        return {
          shipmentItemMapColor: 'light-blue',
          shipmentDetailMapColor: 'green',
        };
      }
      return {
        shipmentItemMapColor: 'yellow accent-4',
        shipmentDetailMapColor: 'red accent-4',
      };
    },
  },
  methods: {
    replaceNoteToHtml,
  },
};
</script>
